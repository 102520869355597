import Component from '@src/libs/components/component';
import { register } from '@src/libs/register';

class PoiCard extends Component {
    constructor(name, root) {
        super(name, root);
    }
}

register.registerClass('.st20-poi-card',PoiCard);
