import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';

class Loader extends Component {
    constructor(name, root) {
        super(name, root);
        this.page = register.getClass(document.querySelector('.sp1-page'));
    }

    open() {
        this.root.classList.add(this._mod('show'));
        this.page.setBlockScroll();
    }

    close() {
        this.page.removeBlockScroll();
        this.root.classList.remove(this._mod('show'));
    }
}

register.registerClass('.st3-loader', Loader);

export const openLoader = (name) => {
    const loader = document.getElementById(`loader-${name}`);
    if (!loader || !loader.objReference) return;
    loader.objReference.open();
};

export const closeLoader = (name) => {
    const loader = document.getElementById(`loader-${name}`);
    if (!loader || !loader.objReference) return;
    loader.objReference.close();
};

window.openLoader = openLoader;
window.closeLoader = closeLoader;
