import Component from '@src/libs/components/component';
import { register } from '@src/libs/register';

class HeroTemplate extends Component {
    constructor(name, root) {
        super(name, root);
    }
}

register.registerClass('.st18-hero-template', HeroTemplate);
