import Component from '@src/libs/components/component';
import { register } from '@src/libs/register';

class Actions extends Component {
    constructor(name, root) {
        super(name, root);

        this.shareBtn = this._dElMod('btn', 'share');
        this.saveBtn = this._dElMod('btn', 'save');
        this.shares = this._dEl('shares');

        let url = this.root.dataset.url;
        const title = this.root.dataset.title;
        if (!url.startsWith('http')) url = window.location.origin + url;
        this.path = this.root.dataset.path;
        if (root.hasAttribute('data-on-top')) {
            this.shares.classList.add(this._elMod('shares', 'onTop'));
        }

        //favorites recipes
        window.searchRecipeInFav(this.saveBtn, this.path);

        //social
        $(this.shares).jsSocials({
            url: url,
            text: title,
            showLabel: false,
            showCount: false,
            shares: ['twitter', 'facebook', 'linkedin', 'pinterest'],
        });

        this._addEventListeners();
    }

    _addEventListeners() {
        this.shareBtn.addEventListener('click', () => {
            const toRight = this._sharesToRight(this.shareBtn, this.shares);
            if (toRight) {
                this.shares.classList.remove(this._elMod('shares', 'toLeft'));
                this.shares.classList.add(this._elMod('shares', 'toRight'));
            } else {
                this.shares.classList.add(this._elMod('shares', 'toLeft'));
                this.shares.classList.remove(this._elMod('shares', 'toRight'));
            }

            if (this.shares.classList.contains(this._elMod('shares', 'show'))) {
                this.shares.classList.remove(this._elMod('shares', 'show'));
            } else {
                this.shares.classList.add(this._elMod('shares', 'show'));
            }
        });

        this.saveBtn.addEventListener('click', (event) => {
            window.clickBookmarked($(event.target), this.path, event.target.dataset.idSave);
        });
    }

    _sharesToRight(shareBtn, shares) {
        const rect = shareBtn.getBoundingClientRect();
        const width = shares.clientWidth;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        let left = rect.left + scrollLeft;
        if (left + width > document.documentElement.scrollWidth) {
            return false;
        }
        return true;
    }
}

register.registerClass('.st11-actions', Actions);
