const styledMapFeatures = [
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "landscape",
      "stylers": [
        {
          "lightness": 50
        }
      ]
    },
    {
      "featureType": "landscape.man_made",
      "elementType": "geometry",
      "stylers": [
        {
          "lightness": 50
        }
      ]
    },
    {
      "featureType": "landscape.natural",
      "stylers": [
        {
          "lightness": 15
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "stylers": [
        {
          "color": "#210303"
        },
        {
          "weight": 1
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#210303"
        },
        {
          "saturation": -100
        },
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#d69494"
        },
        {
          "lightness": 55
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#db7770"
        },
        {
          "saturation": 45
        },
        {
          "lightness": 60
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text",
      "stylers": [
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "stylers": [
        {
          "color": "#bab5b5"
        },
        {
          "saturation": -75
        },
        {
          "lightness": 50
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels",
      "stylers": [
        {
          "color": "#878282"
        },
        {
          "weight": 0.5
        }
      ]
    },
    {
      "featureType": "road.local",
      "stylers": [
        {
          "color": "#bab5b5"
        },
        {
          "saturation": -65
        },
        {
          "lightness": 90
        },
        {
          "weight": 1.5
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
];

export default styledMapFeatures;
