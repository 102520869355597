///////////////////////////////////////////////////
//////////// PROMISIFY EVENT
///////////////////////////////////////////////////
export const buildWaitForEvent = (eventName) => (node, func) =>
    new Promise((resolve, reject) => {
        // reject for invalid node
        if (!(node instanceof window.HTMLElement || node instanceof window.SVGElement)) {
            return reject(new Error('tail-end: an HTML or SVG element is required.'));
        }

        // create the event handler
        const handler = () => {
            // unbind the handler
            node.removeEventListener(eventName, handler);
            // resolve the (now clean) node
            return resolve(node);
        };

        // bind the handler
        node.addEventListener(eventName, handler);

        // if it exists, call the function passing in the node
        if (typeof func === 'function') {
            window.requestAnimationFrame(() => func(node));
        }
    });

///////////////////////////////////////////////////
//////////// PROMISIFY ANIMATIONEND - TRANSITIONEND
///////////////////////////////////////////////////
export const animationEnd = buildWaitForEvent('animationend');
export const transitionEnd = buildWaitForEvent('transitionend');

///////////////////////////////////////////////////
//////////// DELAY
///////////////////////////////////////////////////
export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const nextTick = () => new Promise((resolve) => setTimeout(resolve, 0));

///////////////////////////////////////////////////
//////////// DEEP MERGE
///////////////////////////////////////////////////
export const isObject = (item) => {
    return item && typeof item === 'object' && !Array.isArray(item);
};

export const mergeDeep = (target, ...sources) => {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key])
                    Object.assign(target, {
                        [key]: {},
                    });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, {
                    [key]: source[key],
                });
            }
        }
    }

    return mergeDeep(target, ...sources);
};

///////////////////////////////////////////////////
//////////// NEXT PREV SIBLINGS
///////////////////////////////////////////////////
export const getNextSibling = function (elem, selector) {
    // Get the next sibling element
    var sibling = elem.nextElementSibling;

    // If there's no selector, return the first sibling
    if (!selector) return sibling;

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches && sibling.matches(selector)) return sibling;
        sibling = sibling.nextElementSibling;
    }
};

export const getPrevSibling = function (elem, selector) {
    // Get the next sibling element
    var sibling = elem.previousElementSibling;

    // If there's no selector, return the first sibling
    if (!selector) return sibling;

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches && sibling.matches(selector)) return sibling;
        sibling = sibling.previousElementSibling;
    }
};

export const getNextSiblingAll = function (elem, selector) {
    // Get the next sibling element
    let sibling = elem.nextElementSibling;
    let result = [];
    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches && sibling.matches(selector)) result.push(sibling);
        sibling = sibling.nextElementSibling;
    }
    return result;
};

export const getPrevSiblingAll = function (elem, selector) {
    // Get the next sibling element
    let sibling = elem.previousElementSibling;
    let result = [];
    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches && sibling.matches(selector)) result.push(sibling);
        sibling = sibling.previousElementSibling;
    }
    return result;
};

///////////////////////////////////////////////////
//////////// FIRST AND LAST CHILD
///////////////////////////////////////////////////
export const getFirstChild = function (elem, selector) {
    // Get the first child element
    var nodes = elem.childNodes;
    if (nodes.length <= 0) return;

    // If there's no selector, return the first sibling
    if (!selector) return nodes[0];

    // If the child node matches our selector, use it
    // If not, jump to the next child and continue the loop
    for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].matches && nodes[i].matches(selector)) return nodes[i];
    }
};

export const getLastChild = function (elem, selector) {
    // Get the last child element
    var nodes = elem.childNodes;
    if (nodes.length <= 0) return;

    // If there's no selector, return the last sibling
    if (!selector) return nodes[nodes.length - 1];

    // If the child node matches our selector, use it
    // If not, jump to the prev child and continue the loop
    for (let i = nodes.length - 1; i >= 0; i--) {
        if (nodes[i].matches && nodes[i].matches(selector)) return nodes[i];
    }
};

export const getClosestAll = function (elem, selector) {
    const closestOne = (el, arr) => {
        if (!el) return arr;

        const clo = el.closest(selector);
        if (!clo) return arr;

        return closestOne(clo.parentElement, [clo, ...arr]);
    };

    return closestOne(elem, []);
};


///////////////////////////////////////////////////
//////////// CREATE QUERY PARAMETERS
///////////////////////////////////////////////////
export const buildQuery = (obj) =>
    Object.entries(obj)
        .map((pair) => pair.map(encodeURIComponent).join('='))
        .join('&');

///////////////////////////////////////////////////
//////////// HTML ELEMENTS
///////////////////////////////////////////////////
export const htmlToElement = (html) => {
    var template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
};

export const emptyElement = (element) => {
    while (element.firstChild) {
        element.removeChild(element.lastChild);
    }
};

///////////////////////////////////////////////////
//////////// OTHERS
///////////////////////////////////////////////////
export const isPositive = (value) => {
    if (value == true || value == 'true' || value == 'S' || value == 's' || value == '1') {
        return true;
    }
    return false;
};

///////////////////////////////////////////////////
//////////// COOKIES
///////////////////////////////////////////////////
export const getCookieByName = (name) => {
    var mt = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    if (mt) return mt.pop();
    return '';
};

export const writeCookieWithName = (name, value, domain, expires = undefined) => {
    document.cookie = `${name}=${value};domain=${domain};path=/;${expires ? 'expires=' + expires : ''}`;
};


///////////////////////////////////////////////////
//////////// ASYNC REPLACE
///////////////////////////////////////////////////
/**
 * Replace async
 * @param {*} string
 * @param {*} regexp
 * @param {*} replacerFunction
 * @returns
 */
export const replaceAsync = async (string, regexp, replacerFunction) => {
    const replacements = await Promise.all(Array.from(string.matchAll(regexp), (match) => replacerFunction(...match)));
    let i = 0;
    return string.replace(regexp, () => replacements[i++]);
};

