import Component from '@src/libs/components/component';
import { register } from '@src/libs/register';
import { gMapsHelper } from '@src/libs/gmaps-helper';
import styledMapFeatures from './scripts/styled-map';
import { storeManager } from '@src/libs/store-manager';
import { MapMarker } from '@src/repository/apps/conad-saporie/templates/st23-map-marker/script';
import { forceUpdateLazy } from '@src/repository/apps/conad-saporie/templates/st12-picture/script';
import { runWithData } from '@src/libs/htl-runtime/HTMLRuntime';
import markerTpl from '@src/repository/apps/conad-saporie/templates/st23-map-marker/jscall.html';
import createHTMLMapMarker from '@src/repository/apps/conad-saporie/templates/st23-map-marker/map-marker';

class SaporieMap extends Component {
    constructor(name, root) {
        super(name, root);
        
        this.BREAKPOINT_L = 1024;
        this.id = this.root.getAttribute('id');
        this.mapEl = this._dEl('map');
        this.map = null;
        this.markers = [];
        this.markersMap = {};
        this.selectedMarker = null;
        this.pointOfInterest = JSON.parse(this.root.dataset.poi);

        console.log(this.pointOfInterest);
        
        // get slider object
        this.sliderEl = this._dEl('slider');
        this.slider = register.getClass(this.sliderEl);

        this.init();
        
        this._addEventListeners();
        this._addStoreListeners();
    }

    _addEventListeners() {
        this.root.addEventListener('selectedPoiCard', (event) => {
            const id = event.data.poiMapId;
            this.slider.selectMapCard();
            // setta la mappa sul pin X
            this.selectPoi(id);
            event.stopPropagation();
        });
    }

    _addStoreListeners() {
        storeManager.on('mapSelectedId', async (path, data) => {
            if (!data.mapId) return;
            if (window.innerWidth >= this.BREAKPOINT_L) {
                /*desktop*/
                this.selectPoi(data.mapId);
                forceUpdateLazy();
            } else {
                /*mobile*/
                this.slider.slideTo(parseInt(data.mapId));
                this.selectPoi(data.mapId);
            }
        })
    }

    async init() {
        if (!this.map) {
            const maps = await gMapsHelper.getGMaps();
            this.map = new maps.Map(this.mapEl, {
                center: { lat: parseFloat(this.pointOfInterest[0].poiLatitude), lng: parseFloat(this.pointOfInterest[0].poiLongitude) },
                zoom: 12,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false
            });
            const styledMapType = new maps.StyledMapType(styledMapFeatures);
            this.map.mapTypes.set('styled_map', styledMapType);
            this.map.setMapTypeId('styled_map');
        }
        await this.addPoi(this.pointOfInterest);

        if (window.innerWidth <= this.BREAKPOINT_L) {
            this.selectPoi(0);
        }
        
    }

    async addPoi(pointOfInterest) {
        if (!pointOfInterest || pointOfInterest.length <= 0) return;
        const maps = await gMapsHelper.getGMaps();

        if (this.markers.length > 0) {
            this._emptyMarkers();
        }

        this.currentBounds = new maps.LatLngBounds();
        for (const poi of pointOfInterest) {
            /* get latlng */
            const position = new maps.LatLng(parseFloat(poi.poiLatitude), parseFloat(poi.poiLongitude));
            /* get marker element dynamically */
            const data = MapMarker.getMapMarkerData(
                this.id + '-' + Math.floor(Math.random() * 100000),
                `${this.name}__mapMarker`,
                poi,
            );
            const markerEl = runWithData(markerTpl, data);

            /* create map marker */
            const marker = createHTMLMapMarker({
                latlng: position,
                elem: markerEl,
                map: this.map,
            });
            /* add marker to the map */
            this.markersMap[poi.mapId] = marker;
            this.markers.push(marker);
            this.currentBounds.extend(position);
        }

        this.map.fitBounds(this.currentBounds);
    }

    async selectPoi(mapId) {
        const maps = await gMapsHelper.getGMaps();
        const marker = this.markersMap[mapId];
        if (!marker) {
            console.warn(`cannot find marker for poi ${mapId}`);
            return;
        }

        this._resetMarkers();
        register.getClass(marker.getElem()).setSelected();
        const position = new maps.LatLng(marker.getPosition().lat() - 0.006, marker.getPosition().lng());
        this.map.panTo(position);
        this.map.setZoom(12);
        this.selectedMarker = marker;
    }

    async _addPoiToMap() {
        if (!this.map) return;
        await this.addPoi(this.pointOfInterest);
    }

    _emptyMarkers() {
        for (const marker of this.markers) {
            marker.setMap(null);
        }
        this.markers = [];
        this.markersMap = {};
        this.selectedMarker = null;
    }

    async _resetMarkers() {
        if (!this.markers || this.markers.length <= 0) return;
        this.markers.forEach(marker => {
            register.getClass(marker.getElem()).unsetSelected();
        });
    }
}
register.registerClass('.sc19-map', SaporieMap);