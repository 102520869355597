import Component from '@src/libs/components/component';
import { register } from '@src/libs/register';

class PoiMapCard extends Component {
    constructor(name, root) {
        super(name, root);
    }

    selectCard() {
        this.classList.add('selected');
    }

    deselectCard() {
        this.classList.remove('selected');
    }
}

register.registerClass('.st22-poi-map-card',PoiMapCard);
