import Swiper, { Pagination } from 'swiper';
import Component from '@src/libs/components/component';
import { register } from '@src/libs/register';

Swiper.use([Pagination]);

class Ingredients extends Component {
    constructor(name, root) {
        super(name, root);
        this.slides = root;
        this.carousel = this.slides.querySelector(this._el('carousel', true));
        this._initOrDestroySwiper();
        this._addEventListeners();
    }

    _initOrDestroySwiper() {
        if (window.innerWidth >= 992 && this.swiper) {
            this.swiper.destroy();
            this.swiper = null;
        } else if (window.innerWidth < 992 && !this.swiper) {
            this.swiper = new Swiper(this.carousel, {
                slidesPerView: 3,
                wrapperClass: this._el('track'),
                slideClass: this._el('slide'),
                pagination: {
                    el: this.slides.querySelector(this._el('pagination', true)),
                    bulletClass: this._el('bullet'),
                    bulletActiveClass: this._elMod('bullet', 'active'),
                },
            });
        }
    }

    _addEventListeners() {
        window.addEventListener('resize', () => {
            this._initOrDestroySwiper();
        });
    }
}

register.registerClass('.akt-recipes-ingredients', Ingredients);
