import { Loader } from '@googlemaps/js-api-loader';
import { buildWaitForEvent } from './utils';

const gMapsLoaded = buildWaitForEvent('gMapsLoaded');

class GMapsHelper {
    constructor() {
        this.loader = new Loader({
            apiKey: window.gMapsApi,
            version: 'weekly',
            libraries: ['places'],
        });
        this.status = 'unloaded';
    }

    async getGMaps() {
        if (this.status == 'loaded') {
            return window.google.maps;
        } else if (this.status == 'loading') {
            await gMapsLoaded(document.body);
            return window.google.maps;
        } else if (this.status == 'unloaded') {
            this.status = 'loading';
            try {
                await this.loadGMaps();
                this.status = 'loaded';
                return window.google.maps;
            } catch (error) {
                console.error(error);
                this.status = 'error';
            }
        } else {
            console.error('Cannot get gmaps js api');
        }
    }

    async loadGMaps() {
        await this.loader.load();
        const event = new CustomEvent('gMapsLoaded');
        document.body.dispatchEvent(event);
    }
}

const defaultGMapsHelper = new GMapsHelper();
window.ecGMapsHelper = defaultGMapsHelper;
export const gMapsHelper = defaultGMapsHelper;
