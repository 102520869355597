import Component from '@src/libs/components/component';
import { register } from '@src/libs/register';

class AktRecipesSimilarMobile extends Component {
    constructor(name, root) {
        super(name, root);
        this.header = this._dElMod('box','header');
        this.content = this._dElMod('box','content');
        this.arrow = this._dElMod('box','arrow');
        this._addEventListener();
    }

    _addEventListener() {
        window.addEventListener('scroll', () => {
            this._detectFooterInViewport();
        });

        this.header.addEventListener('click', () => {
            this.content.classList.toggle(this._elMod('box','close'));
            this.arrow.classList.toggle(this._elMod('box','arrowOpen'));

        });

    }

    _detectFooterInViewport() {
        let footer = document.getElementById('footer');
        if (!footer) return;

        let footer_rect = footer.getBoundingClientRect(),
            box = this.root.querySelector('.akt-recipes-similar--mobile__box--content'),
            box_rect = box.getBoundingClientRect();

        const fadeOut = 'akt-recipes-similar--fadeOut';

        /* take into account &--fadeOut bottom of -55px when reappearing */
        if (footer_rect.top > box_rect.top - 55) {
            let rootClasses = this.root.classList;
            if (rootClasses.contains(fadeOut)) rootClasses.remove(fadeOut);
            return;
        }
        if (footer_rect.top < box_rect.top) {
            let rootClasses = this.root.classList;
            if (!rootClasses.contains(fadeOut)) rootClasses.add(fadeOut);
            return;
        }
    }
}

register.registerClass('.akt-recipes-similar--mobile', AktRecipesSimilarMobile);
