/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];
  let wcmmode = $.globals["wcmmode"];

  (function _template_src_main_webpack_repository_apps_conad_saporie_templates_st10_tag_st10_tag_html(){ 
    let render;
    render = function* _template_src_main_webpack_repository_apps_conad_saporie_templates_st10_tag_st10_tag_html_render(args) { 
      let uniqueid = args[1]['uniqueId'] || '';
      let extraclasses = args[1]['extraClasses'] || '';
      let label = args[1]['label'] || '';
      let key = args[1]['key'] || '';
      let value = args[1]['value'] || '';
      let $t, $n = args[0];
      $.dom.text($n,"\n    ");
      $t = $.dom.create("button",false,false);
      const var_attrValue0 = (uniqueid) + ("-tag");
      if (!$.col.empty(var_attrValue0)) {
        $.dom.attr($t, 'id', var_attrValue0, 'attribute');
      }
      const var_attrValue1 = ("st10-tag ") + (extraclasses);
      if (!$.col.empty(var_attrValue1)) {
        $.dom.attr($t, 'class', var_attrValue1, 'attribute');
      }
      const var_attrValue2 = key;
      if (!$.col.empty(var_attrValue2)) {
        $.dom.attr($t, 'data-key', var_attrValue2, 'attribute');
      }
      const var_attrValue3 = value;
      if (!$.col.empty(var_attrValue3)) {
        $.dom.attr($t, 'data-value', var_attrValue3, 'attribute');
      }
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n        ");
      const var_4 = yield $.xss(label, "html");
      $.dom.append($n, var_4);
      $.dom.text($n,"\n    ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n");
    };
    $.template('src/main/webpack/repository/apps/conad-saporie/templates/st10-tag/st10-tag.html', 'render', render);
  })();

  return $.run(function* () {
    let $t, $n = $.dom.start();
    $.dom.text($n,"\n");
    let tag = $.template('src/main/webpack/repository/apps/conad-saporie/templates/st10-tag/st10-tag.html');
    $.dom.text($n,"\n");
    yield $.call(tag["render"], [$n, {"uniqueId": data["uniqueId"], "extraClasses": data["extraClasses"], "label": data["label"], "value": data["value"], "key": data["key"], }]);
    $.dom.text($n,"\n");
    return $.dom.end();

  });
};
