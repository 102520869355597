import { buildWaitForEvent, nextTick } from './utils';

const waitLoggedUser = buildWaitForEvent('ntLoggedEvent');

export class UserService {
    constructor() {}

    async getUser() {
        if (!window.NT_LOGGED_USER) await waitLoggedUser(document.body);
        else await nextTick();
        if (window.NT_LOGGED_USER == 'anonymous') return null;
        else return window.NT_LOGGED_USER;
    }
}

window.rcUserService = new UserService();

/**
 *
 * @returns {UserService} the tracking manager
 */
export const getUserService = () => {
    return window.rcUserService;
};

export const userManager = window.rcUserService;
