import Component from '@src/libs/components/component';
import { register } from '@src/libs/register';

class PoiInfo extends Component {
    constructor(name, root) {
        super(name, root);
        }
}

register.registerClass('.st21-poi-info', PoiInfo);
