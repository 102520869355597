import Component from '@src/libs/components/component';
import { register } from '@src/libs/register';

class Glossary extends Component {
    constructor(name, root) {
        super(name, root);
        this.currentLetter = 'a';
        
        this.parent =  this._dEl('anchors');
        this.anchors = this._dEl('anchors--list');
        this.currentHash = window.location.hash.substr(1);

        if(this.currentHash)
            this.currentLetter = this.currentHash[0].toLowerCase();
        
        this.lastScroll = window.scrollY || window.pageYOffset;
        this.initialOffsetY = this.root.getBoundingClientRect().top;
        this._addEventListeners();
        this._selectLetter();
    }
    _addEventListeners() {
        window.addEventListener('hashchange', () => {
            this.currentHash = window.location.hash.substr(1);
            this.currentLetter = this.currentHash[0].toLowerCase();
            this._selectLetter();
        });
        document.addEventListener('scroll', () => {
            this._handleScroll();
        }, { passive: true });
        window.addEventListener('ss4MastheadVisible', (event) => {
            event.data.visible
                ? this.parent.classList.add(this._mod('belowHeader'))
                : this.parent.classList.remove(this._mod('belowHeader'));
        });
    }

    _selectLetter() {
        if(!this.root.hasAttribute("data-enabled"))
            return;

        const items = document.querySelectorAll('.sc20-glossary-item');
        const links = this.anchors.querySelectorAll(this._el('anchors--link', true));
        const linksParent = this.anchors.querySelectorAll(this._el('anchors--item', true));

        for (let i = 0; i < items.length; i++) {
            var ref = items[i].dataset.item;
            if(ref.startsWith(this.currentLetter)) {
                items[i].classList.remove('sc20-glossary-item--hide');
                items[i].parentElement.classList.add('sl1-layout__item--spacedTop2x');
            } else {
                items[i].classList.add('sc20-glossary-item--hide');
                items[i].parentElement.classList.remove('sl1-layout__item--spacedTop2x');
            }
        }

        for (let i = 0; i < links.length; i++) {
            if (links[i].title.toLowerCase() == this.currentLetter) {
                links[i].classList.add(this._mod('activeLink'));
                linksParent[i].classList.add(this._mod('active'));
            } else {
                links[i].classList.remove(this._mod('activeLink'));
                linksParent[i].classList.remove(this._mod('active'));
            }
        }

        if(this.currentHash) {
            const selected = document.querySelector('[data-item='+this.currentHash+']');
            if(selected) {
                var pos = selected.getBoundingClientRect().top - 190;
                window.scrollBy(0,pos);
            }
        }
        
    }

    _handleScroll() {
        const scrollY = window.scrollY;
        /* take into account masthead height for mobile/desktop */
        if (scrollY + (window.innerWidth < 992 ? 75 : 110) <= this.initialOffsetY) {
            this.parent.classList.remove(this._mod('fixed'));
            return;
        }
        const limitScroll = window.pageYOffset + this.parent.getBoundingClientRect().top;

        
        if (scrollY > limitScroll) {
            const diff = scrollY - this.lastScroll;
            if (diff > 0) {
                this.parent.classList.add(this._mod('fixed'));
            } else if (diff < 0) {
                this.parent.classList.remove(this._mod('fixed'));
            }
        } else {
            this.parent.classList.add(this._mod('fixed'));
        }
        

        this.lastScroll = scrollY;
    }
}

register.registerClass('.ss10-glossary', Glossary);
