import Component from '../../../../../../libs/components/component';
import { register } from '../../../../../../libs/register';
import { storeManager } from '@src/libs/store-manager';

class Masthead extends Component {
    constructor(name, root) {
        super(name, root);

        this.lastScroll = window.scrollY || window.pageYOffset;
        this.page = register.getClass(document.querySelector('.sp1-page'));
        this.hamburger = this._dEl('hamburger');
        this.menuMobile = this._dEl('menu-mobile');
        this.menuMobileObj = register.getClass(this.menuMobile);
        this.navMenu = register.getClass(this._dEl('navMenu'));
        this.navItems = this._dEl('navItems');
        this.searchBtn = this._dElMod('btn', 'search');
        this.loggedBtn = this._dElMod('btn', 'logged');
        this.searchBar = this._dEl('searchBar');
        this.filtersEl = this._dEl('filters');
        if (this.filtersEl) this.filters = register.getClass(this.filtersEl);
        this.loginpopup = register.getClass(this._dEl('loginpopup'));
        this.searchCancelBtn = this._dEl('searchCancel');
        this.searchCancelLabel = this._dEl('searchCancelLabel');
        this.searchInput = this._dEl('searchInput');
        this._addEventListeners();
        this._addStoreListeners();
    }

    _addEventListeners() {
        document.addEventListener(
            'scroll',
            () => {
                this._setHeaderScroll();
            },
            { passive: true }
        );

        this.hamburger.addEventListener('click', () => {
            if (this.hamburger.classList.contains(this._elMod('hamburger', 'open'))) {
                this._closeMobileMenu();
            } else {
                this._openMobileMenu();
            }
        });

        this.searchCancelLabel.addEventListener('click', () => {
            window.history.back();
        });

        this.root.addEventListener('click', (event) => {
            if (!event.target.matches(this._el('navItem', true))) return;
            this._toggleNav(event.target);
        });

        this.root.addEventListener('ecNavMenuClosed', () => {
            this.root.classList.remove(this._mod('navOpened'));
            this._resetNavItems();
        });

        if (!this.root.classList.contains(this._mod('search'))) {
            this.searchBtn.addEventListener('click', () => {
                if (this.filters) this.filters.closeFilter();
                this.navMenu.close();
                if (this.searchBar.classList.contains(this._elMod('searchBar', 'open'))) {
                    //this.page.removeBlockScroll();
                    this.searchBtn.classList.remove(this._elMod('btn', 'opened'));
                    this.searchBar.classList.remove(this._elMod('searchBar', 'open'));
                } else {
                    //this.page.setBlockScroll();
                    this.searchBtn.classList.add(this._elMod('btn', 'opened'));
                    this.searchBar.classList.add(this._elMod('searchBar', 'open'));
                    const value = this.searchInput.value;
                    this.searchInput.focus();
                    this.searchInput.value = '';
                    this.searchInput.value = value;
                }
            });
        }

        if (this.searchCancelBtn && this.searchInput) {
            this.searchCancelBtn.addEventListener('click', () => {
                this.searchInput.value = '';
            });
        }

        this.loggedBtn.addEventListener('click', () => {
            if (this.loginpopup.isOpen()) {
                this.loginpopup.close();
                this.loggedBtnShowClosed(false);
            } else {
                this.loginpopup.open();
                this.loggedBtnShowClosed(true);
            }
        });
    }

    _addStoreListeners() {
        storeManager.on('ss4HideClose', async (path, data) => {
            if (data.hide) {
                this.loggedBtnShowClosed(false);
            }
        });
    }

    loggedBtnShowClosed(show = true) {
        setTimeout(() => {
            if (show) {
                this.loggedBtn.classList.add(this._el('menuOpen'));
                this.loggedBtn.setAttribute('aria-label', this.loggedBtn.dataset.closeLabel);
            } else {
                this.loggedBtn.classList.remove(this._el('menuOpen'));
                this.loggedBtn.setAttribute('aria-label', this.loggedBtn.dataset.openLabel);
            }
        }, 200);
    }

    _setHeaderScroll() {
        const scrollY = window.scrollY || window.pageYOffset;
        const limitScroll = this.root.offsetHeight;

        //change header position from standard to fixed during scroll
        if (scrollY > 0 && !this.root.classList.contains(this._mod('scrolled'))) {
            this.root.classList.add(this._mod('scrolled'));
        } else if (scrollY <= 0) {
            this.root.classList.remove(this._mod('scrolled'));
        }

        //hide/show menu during scroll up/down
        const headerVisibleEvent = new CustomEvent('ss4MastheadVisible', { bubbles: true });
        if (scrollY > limitScroll) {
            const diff = scrollY - this.lastScroll;
            if (diff > 0) {
                this.root.classList.add(this._mod('hidden'));
                /* signal that the header became hidden */
                headerVisibleEvent.data = { visible: false };
                this.root.dispatchEvent(headerVisibleEvent);
            } else if (diff < 0) {
                this.root.classList.remove(this._mod('hidden'));
                /* signal that the header became visible */
                headerVisibleEvent.data = { visible: true };
                this.root.dispatchEvent(headerVisibleEvent);
            }
        } else {
            this.root.classList.remove(this._mod('hidden'));
            /* signal that the header became visible */
            headerVisibleEvent.data = { visible: true };
            this.root.dispatchEvent(headerVisibleEvent);
        }

        this.lastScroll = scrollY;
    }

    _openMobileMenu() {
        this.hamburger.classList.add(this._elMod('hamburger', 'open'));
        this.menuMobileObj.open();
    }

    _closeMobileMenu() {
        this.hamburger.classList.remove(this._elMod('hamburger', 'open'));
        this.menuMobileObj.close();
    }

    _toggleNav(navItem) {
        const value = navItem.dataset.value;
        if (!value) return;

        if (this.searchBar && this.searchBar.classList.contains(this._elMod('searchBar', 'open'))) {
            //this.page.removeBlockScroll();
            this.searchBtn.classList.remove(this._elMod('btn', 'opened'));
            this.searchBar.classList.remove(this._elMod('searchBar', 'open'));
        }

        if (this.filters) this.filters.closeFilter();

        if (this.navMenu.isOpen(value)) {
            this.navMenu.close();
        } else {
            this.root.classList.add(this._mod('navOpened'));
            this._resetNavItems();
            navItem.closest('li').classList.add('selected');
            this.navMenu.open(value);
        }
    }

    _resetNavItems() {
        const navItems = Array.from(this.navItems.querySelectorAll('li.selected'));
        navItems.forEach((element) => {
            element.classList.remove('selected');
        });
    }
}

register.registerClass('.ss4-masthead', Masthead);
