import PopupComponent from '../../../../../../libs/components/popup-component';
import { register } from '../../../../../../libs/register';
import { emptyElement } from '../../../../../../libs/utils';
import axios from 'axios';
import PerfectScrollbar from 'perfect-scrollbar';
import { forceUpdateLazy } from '../../../templates/st12-picture/script';
import { forceResizeVideo } from '../../../templates/st16-video/script';

class Recipes extends PopupComponent {
    constructor(name, root) {
        super(name, root);
        this.accordions = this._dEl('accordion', true);
        this.endpoint = root.dataset.endpoint;
        this.cards = this._dEl('cards');

        const scrollbarOptions = {
            swipeEasing: true,
            suppressScrollX: true,
        };
        this.ps = new PerfectScrollbar(root, scrollbarOptions);

        this._addEventListener();
    }

    _addEventListener() {
        this.root.addEventListener('click', (event) => {
            if (!event.target.matches(this._el('accHeading', true))) {
                return;
            }
            const accordion = event.target.closest(this._el('accordion', true));
            if (accordion == null) return;

            if (accordion.classList.contains(this._elMod('accordion', 'active'))) {
                accordion.classList.remove(this._elMod('accordion', 'active'));
            } else {
                accordion.classList.add(this._elMod('accordion', 'active'));
                forceResizeVideo(accordion);
            }

            this.ps.update();
        });
    }

    async _onOpen() {
        try {
            //get recipes
            const storage = window.getRecipesStorage();
            if (!storage) return;

            var recipes = JSON.parse(storage);
            const recipesPaths = recipes.map((item) => item.path);

            const params = new URLSearchParams({ __eck: 'nocache', recipes: recipesPaths });
            const result = await axios({
                method: 'get',
                url: this.endpoint,
                responseType: 'text',
                params,
            });

            //write recipes
            const parser = new DOMParser();
            const doc = parser.parseFromString(result.data, 'text/html');
            const accordions = doc.querySelectorAll(this._el('accordion', true));
            emptyElement(this.cards);
            this.cards.append(...accordions);
            forceUpdateLazy();
        } catch (error) {
            console.warn(error);
        }
    }
}

register.registerClass('.sp2-recipes', Recipes);
