import Component from '@src/libs/components/component';
import { register } from '@src/libs/register';

class RecipeCard extends Component {
    constructor(name, root) {
        super(name, root);
    }
}

register.registerClass('.st1-recipe-card', RecipeCard);
