import Component from '../../../../../../libs/components/component';
import { register } from '../../../../../../libs/register';

class Banner extends Component {
    constructor(name, root) {
        super(name, root);
    }
}

register.registerClass('.sc6-banner', Banner);
