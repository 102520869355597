import PopupComponent from '../../../../../../libs/components/popup-component';
import { register } from '../../../../../../libs/register';
import { getUserService } from '../../../../../../libs/user-service';
import { getCookieByName, writeCookieWithName } from '../../../../../../libs/utils';
import { getTrackingManager } from '../../../../../../libs/tracking-manager';

export default class RefreshCPopup extends PopupComponent {
    constructor(name, root) {
        super(name, root);
        this.trackingManager = getTrackingManager();

        this._addListeners();
        this._checkShow();
    }

    /* override */
    async _onOpen() {
        await super._onOpen();
        //track pageView popup-c-refresh
        this.trackingManager.track(this.root, {
            event: 'pageview',
            funnel: {
                nomeFunnel: 'Conad Refresh',
                stepFunnel: 'Pop up partecipazione concorso',
            },
        });
    }

    _addListeners() {}

    async _checkShow() {
        let user = await getUserService().getUser();
        if (!user) return;

        if(!user.refresh) return;

        let cookieRefresh = getCookieByName('ecRefreshBanner');
        if (cookieRefresh) return;

        const popup = this.getPopup();
        popup.open();

        // get domain
        var domainParts = location.hostname.split('.');
        var domain = location.hostname;
        if (domainParts.length >= 2) {
            domain = '.' + domainParts.slice(-2).join('.');
        }

        // write cookie
        var currentDate = new Date();
        var expiringDate = new Date(currentDate.getTime() + 172800000);
        writeCookieWithName('ecRefreshBanner', 'true', domain, expiringDate.toUTCString());
    }

    /* override */
    async _onClose() {
        await super._onClose();
        this.trackingManager.track(this.root, {
            event: 'uscitaPopupRefresh',
            funnel: {
                nomeFunnel: 'Conad Refresh',
                stepFunnel: 'Pop up partecipazione concorso - Esci',
            },
        });
    }
}

register.registerClass('.sp1-c-refresh', RefreshCPopup);
