import Component from '@src/libs/components/component';
import { register } from '@src/libs/register';
import Swiper, { Pagination, Navigation } from 'swiper';

Swiper.use([Pagination, Navigation]);

class Slider extends Component {
    constructor(name, root) {
        super(name, root);

        this.carousel = this._dEl('carousel');
        this.pagination = this._dEl('pagination');
        this.prev = this._dElMod('btn', 'prev');
        this.next = this._dElMod('btn', 'next');
        this.swiper = null;
        this.slide = this._dEl('slideContainer');
        this.mapFlag = root.hasAttribute('data-map');
        this._initSwiper();
    }

    _initSwiper() {
        const slideCounts = this._dEl('slideContainer', true).length;
        if (this.pagination && slideCounts <= 1) {
            this.pagination.classList.add(this._elMod('pagination', 'hidden'));
        }
        if (this.prev && this.next && slideCounts <= 1) {
            this.prev.classList.add(this._elMod('prev', 'hidden'));
            this.next.classList.add(this._elMod('next', 'hidden'));
        }

        const options = {
            slidesPerView: this.root.dataset.slidesPerView ? parseInt(this.root.dataset.slidesPerView) : 'auto',
            wrapperClass: this._el('track'),
            slideClass: this._el('slideContainer'),
            spaceBetween: this.root.dataset.spacedBetween ? parseInt(this.root.dataset.spacedBetween) : 0,
            slidesOffsetBefore: this.root.dataset.spacedLateral ? parseInt(this.root.dataset.spacedLateral) : 0,
            slidesOffsetAfter: this.root.dataset.spacedLateral ? parseInt(this.root.dataset.spacedLateral) : 0,
            watchOverflow: true,
            observer: true,
            observeParents: true
        };

        if (this.root.dataset.spacedLateralDsk) {
            options['breakpoints'] = {
                992: {
                    slidesOffsetBefore: this.root.dataset.spacedLateralDsk
                        ? parseInt(this.root.dataset.spacedLateralDsk)
                        : 0,
                    slidesOffsetAfter: this.root.dataset.spacedLateralDsk
                        ? parseInt(this.root.dataset.spacedLateralDsk)
                        : 0,
                },
            };
        }

        if (this.pagination) {
            options['pagination'] = {
                el: this.root.querySelector(this._el('pagination', true)),
                bulletClass: this._el('bullet'),
                bulletActiveClass: this._elMod('bullet', 'active'),
            };
        }

        if (this.prev && this.next) {
            options['navigation'] = {
                nextEl: this.next,
                prevEl: this.prev,
                disabledClass: this._elMod('btn', 'disabled'),
                hiddenClass: this._elMod('btn', 'hidden'),
            };
        }

        if(this.mapFlag) {
            options['on'] = {
                    slideChange: () => {
                        this._selectedCard();
                    },
                    beforeSlideChangeStart: () => { 
                        //this._deselectMapCard();
                    }
            }
        }

        this.swiper = new Swiper(this.carousel, options);

        if(this.mapFlag) {
            this.selectMapCard();
        }
        
    }

    selectMapCard() {
        this.actIndex = this.swiper.realIndex;
        this.slide = this.swiper.slides[this.actIndex];
        this.slide.classList.add('st22-poi-map-card__selected');
        this._deselectMapCard();
    }

    _deselectMapCard() {
        this.actIndex = this.swiper.realIndex;
        var i;
        for(i = 0; i < this.swiper.slides.length; i++) {
            if(i!=this.actIndex){
                this.swiper.slides[i].classList.remove('st22-poi-map-card__selected');
            }
        }
    }

    _selectedCard() {
        this.actIndex = this.swiper.realIndex;
        const event = new CustomEvent('selectedPoiCard', { bubbles: true });
        event.data = {
            poiMapId: this.actIndex,
        };
        this.selectMapCard();
        this.root.dispatchEvent(event);
    }

    slideTo(num) {
        this.swiper.slideTo(num, 200, false);
    }
}

register.registerClass('.st6-slider', Slider);
