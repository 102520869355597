import LazyLoad from 'vanilla-lazyload';

const lazyLoad = new LazyLoad({
    elements_selector: '.st12-picture--lazy .st12-picture__img',
    class_loaded: 'st12-picture__img--loaded',
    callback_loaded: (el) => {
        el.closest('picture').classList.add('st12-picture--loaded');
        var event = new CustomEvent('st12PictureLoaded', { bubbles: true });
        el.dispatchEvent(event);
    },
});

(() => {
    //reload images if adobe target load a dynamic image
    if (window.adobe && window.adobe.target) {
        document.addEventListener(window.adobe.target.event.CONTENT_RENDERING_SUCCEEDED, function () {
            lazyLoad.update();
        });
    }
    lazyLoad.update();
})();

export const forceUpdateLazy = () => {
    lazyLoad.update();
};
