import Swiper, { Pagination } from 'swiper';
import Component from '@src/libs/components/component';
import { register } from '@src/libs/register';

Swiper.use([Pagination]);

class RelatedArticles extends Component {
    constructor(name, root) {
        super(name, root);
        this.articles = root;
        this.slides = this.articles.querySelectorAll('.akt-recipes-related-articles__slide');
        this.carousel = this.articles.querySelector(this._el('carousel', true));
        this._initOrDestroySwiper();
        this._addEventListeners();
    }

    _initOrDestroySwiper() {
        if (window.innerWidth >= 992 && this.swiper) {
            this.swiper.destroy();
            this.swiper = null;
        } else if (window.innerWidth < 992 && !this.swiper) {
            this.swiper = new Swiper(this.carousel, {
                slidesPerView: 1,
                wrapperClass: this._el('track'),
                slideClass: this._el('slide'),
                pagination: {
                    el: this.articles.querySelector(this._el('pagination', true)),
                    bulletClass: this._el('bullet'),
                    bulletActiveClass: this._elMod('bullet', 'active'),
                },
            });
        }
    }

    _addEventListeners() {
        window.addEventListener('resize', () => {
            this._initOrDestroySwiper();
        });
        for (let slide of this.slides) {
            let slideShare = slide.querySelector('.akt-recipes-related-articles__slideBottom--ctaIcon.akt-share');
            slideShare?.addEventListener('click', (event) => {
                let shareContent = event.target.nextElementSibling;
                document.querySelectorAll('.akt-recipes-related-articles__shareContent').forEach((item) => {
                    if (item !== shareContent) {
                        item.classList.remove('c-show');
                    }
                });
                let classes = shareContent.classList;
                classes.contains('c-show') ? classes.remove('c-show') : classes.add('c-show');
            });
        }
    }
}

register.registerClass('.akt-recipes-related-articles', RelatedArticles);
