import Component from '@src/libs/components/component';
import { register } from '@src/libs/register';

class Anchors extends Component {
    constructor(name, root) {
        super(name, root);
        this.anchors = this._dEl('list');
        this.currentHash = window.location.hash.substr(1);
        this.lastScroll = window.scrollY || window.pageYOffset;
        this.initialOffsetY = this.root.getBoundingClientRect().top;
        this._addEventListeners();
        this._selectAnchor();
    }
    _addEventListeners() {
        window.addEventListener('hashchange', () => {
            this.currentHash = window.location.hash.substr(1);
            this._selectAnchor();
        });
        document.addEventListener('scroll', () => {
            this._handleScroll();
        }, { passive: true });
        window.addEventListener('ss4MastheadVisible', (event) => {
            event.data.visible
                ? this.root.classList.add(this._mod('belowHeader'))
                : this.root.classList.remove(this._mod('belowHeader'));
        });
    }

    _selectAnchor() {
        const links = this.anchors.querySelectorAll(this._el('link', true));
        for (let i = 0; i < links.length; i++) {
            const href = links[i].href;
            if (this.currentHash && href.indexOf('#' + this.currentHash) >= 0) {
                links[i].classList.add('active');
            } else {
                links[i].classList.remove('active');
            }
        }
    }

    _handleScroll() {
        const scrollY = window.scrollY;
        /* take into account masthead height for mobile/desktop */
        if (scrollY + (window.innerWidth < 992 ? 75 : 110) <= this.initialOffsetY) {
            this.root.classList.remove(this._mod('fixed'));
            return;
        }
        const limitScroll = window.pageYOffset + this.root.getBoundingClientRect().top;

        
        if (scrollY > limitScroll) {
            const diff = scrollY - this.lastScroll;
            if (diff > 0) {
                this.root.classList.add(this._mod('fixed'));
            } else if (diff < 0) {
                this.root.classList.remove(this._mod('fixed'));
            }
        } else {
            this.root.classList.add(this._mod('fixed'));
        }
        

        this.lastScroll = scrollY;
    }
}

register.registerClass('.ss6-anchors', Anchors);
